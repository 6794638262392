















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  InfeedConfExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    const storeUseList = ref<itemHierarchyUseList>([['StoreShelve', 'Store']])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfStore = ref<itemHierarchyGroupExtractCondition>({})

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const base: InfeedConfExtractCondition = {
        storeShelve: extractConditionOfStore.value.storeShelve || [],
        storeShelveCount: extractConditionOfStore.value.storeShelveCount || 0,
        store: extractConditionOfStore.value.store || [],
        storeCount: extractConditionOfStore.value.storeCount || 0,
      }

      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = [
      '店舗エリア・店舗 のいずれかを一つ以上選択',
    ]
    return {
      requiredItemInput,
      storeUseList,
      changeExtractConditionOfStore,
    }
  },
})
