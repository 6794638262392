
















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/order-budget/infeed-conf/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import { 
  InfeedConfExtractCondition,
  InfeedConfOutputCondition,
  ColumnLabel 
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPWeeklyDailyBudgetConfsCountDocument,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  OUTPUT_COND_STORE,
  SELECT_MAX_COUNT, 
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [
    OUTPUT_COND_STORE.all,
    OUTPUT_COND_STORE.area,
    OUTPUT_COND_STORE.tanten,
  ],  
  delOutput: [],
}

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPBudgetInfeedConf,
      templateFile: TemplateFile.UiPBudgetInfeedConf_01,
      extractCondition: {
      },
      // totalCount: null,
      // exportSpinner: false,
      exportCondition: false,
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput
        },
      ],
    }
  },
  methods: {
    changeExtractCondition(cond: InfeedConfExtractCondition) {
      console.log('NewStoreBulkOrderExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: InfeedConfOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      // this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPWeeklyDailyBudgetConfsCountDocument,
            variables: { where: null/*this._createWhere()*/ },
          })
          const data = result.data
          // this.totalCount = data.vUiPBudgetInfeedConfs.totalCount.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          // this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        // this.totalCount = '-'
      }
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      return true
    },    
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      console.log('requestExport {query}')
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPBudgetInfeedConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.infeed_conf,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const where: Where[] = this._createWhere()
      const rowQuery = `
        query c {
          vUiPBudgetInfeedConfs(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: []
          ) {
            edges {
              node {
                inactiveFlg
                storeApplyLevel
                storeApplyScope
                vUiMStoreApplyLevel {
                  storeApplyScopeName
                }
                vUiPBudgetGroup{
                  uiMCategory1{
                    categoryName
                  }
                  uiMCategory2{
                    categoryName
                  }
                  uiMCategory3{
                    categoryName
                  }
                }
                budgetGroupId
                infeedRate
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
     _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        // 店舗コード, 店舗エリア
        {
          field: ['vUiMStoreApplyLevel/validStores/storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        // 出力条件
        { field: ['storeApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
        // 設定行フラグ　1固定
        { field: ['configuredFlg'], op: WhereOp.In, value: ['1'] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/validStores/storeCd',
        this.extractCondition.store
      )
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/vUiPAreas/areaCd',
        this.extractCondition.storeShelve
      )
      setWhereValue(where, 'storeApplyLevel', this.outputCondition.storeOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      return where
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'vUiMStoreApplyLevel',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'budgetGroupId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory1.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory2.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory3.categoryName',
          to: '',
        },
        {
          from: 'budgetGroupId',
          to: '',
        },
        {
          from: 'storeApplyLevel',
          to: '',
        },
        {
          from: 'storeApplyLevelName',
          to: '',
        },
        {
          from: 'storeApplyScope',
          to: '',
        },
        {
          from: 'storeApplyScopeName',
          to: '',
        },
        {
          from: 'infeedRate',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
