



























import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  PropType,
} from '@vue/composition-api'

import ExportImportButton from '@/components/molecules/h/ExportImportButton.vue'
import ExtractCondition from '@/components/organisms/h/page/order-budget/infeed-conf/ExtractCondition.vue'
import OutputCondition from '@/components/organisms/h/page/order-budget/infeed-conf/OutputCondition.vue'
import TitleAndManipulate from '@/components/organisms/h/TitleAndManipulate.vue'
import {
  outputCondUseInfoList,
  InfeedConfExtractCondition,
  InfeedConfOutputCondition,
} from '@/types/entities'
export default defineComponent({
  components: {
    TitleAndManipulate,
    ExtractCondition,
    OutputCondition,
    ExportImportButton,
  },
  props: {
    outputUseList: {
      type: Array as PropType<outputCondUseInfoList>,
      default: () => [],
    },
    totalCount: {
      type: String as PropType<string>,
      default: null,
    },
    exportSpinner: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(_, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      // should be called in setup
      return
    }
    const { $i18n } = instance.proxy

    const requestExport = () => {
      context.emit('request-export')
    }
    const requestImport = () => {
      context.emit('request-import')
    }
    const changeExtractCondition = (cond: InfeedConfExtractCondition) => {
      console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    const changeOutputCondition = (
      cond: InfeedConfExtractCondition) => {
      context.emit('change-output-condition', cond)
    }

    return {
      requestExport,
      requestImport,
      changeExtractCondition,
      changeOutputCondition,
    }
  },
})
